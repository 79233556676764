<template>
  <div>
    <nav class="navbar navbar-light bg-white display-web" variant="faded" id="navbarpackages" type="light">
        <div class="container-fluid">
            <a href="/" class="navbar-brand ml-5"><img class="logos" crossorigin="anonymous" :src="this.$logo" alt=""></a>
            <div class="d-flex mr-5">
              <div v-if="!isLogin">
                <router-link to="/login" class="default-text-style"><b>Sign In</b></router-link>
              </div>
              <div v-if="isLogin">
                  <b-dropdown id="dropdown-dropup" dropup right class="bg-transparent profile-dropdown">
                  <template #button-content>
                      <div class="avatar-xs">
                          <span class="avatar-title rounded-circle">{{nama.charAt(0).toUpperCase()}}</span>
                      </div>
                  </template>
                  <b-dropdown-header id="dropdown-header-label">
                    <div class="d-flex">
                      <b-avatar variant="primary" :text="nama.charAt(0).toUpperCase()" class="mr-2 avatar-xs"></b-avatar>
                      <div class="d-flex justify-content-start align-items-center">
                        <span class="user-name default-text-style-profile"><span class="letter05">{{nama}}</span></span>
                        <!-- <span class="d-none d-xl-inline-block mx-2 default-text-style-profile font-size-12"> <i class="bx bxs-circle text-green"></i> <span class="text-muted letter05"> Active</span></span> -->
                      </div>
                    </div>
                  </b-dropdown-header>
                  <b-dropdown-item href="/settings">
                    <img src="@/assets/images/icon/setting.svg" alt height="100%" />
                    <span class="ml-3 default-text-style-profile">Settings</span>
                  </b-dropdown-item>
                  <b-dropdown-item href="/packages">
                    <img src="@/assets/images/icon/upgrade.svg" alt height="100%" />
                    <span class="ml-3 default-text-style-profile">Upgrade</span>
                  </b-dropdown-item>
                  <!-- <b-dropdown-item href="/payment/histories">
                    <img src="@/assets/images/icon/payment-card.png" alt height="100%" />
                    <span class="ml-3 default-text-style-profile">Payment History</span>
                  </b-dropdown-item> -->
                  <b-dropdown-divider></b-dropdown-divider>
                  <b-dropdown-item href="/logout">
                    <img src="@/assets/images/icon/logout.svg" alt height="100%" />
                    <span class="ml-3 default-text-style-profile">Sign Out</span>
                  </b-dropdown-item>
                </b-dropdown>
              </div>
              <Profile/>
              <Password/>
              <EditProfile/>
            </div>
        </div>
    </nav>
    <nav class="navbar navbar-light bg-white display-mobile" variant="faded" id="navbarpackages" type="light">
        <div class="container-fluid">
            <a href="/" class="navbar-brand"><img class="logos" crossorigin="anonymous" :src="this.$logo" alt=""></a>
            <div class="d-flex">
              <div v-if="!isLogin">
                <router-link to="/login" class="default-text-style"><b>Sign In</b></router-link>
              </div>
              <div v-if="isLogin">
                  <b-dropdown id="dropdown-dropup" dropup right class="bg-transparent profile-dropdown">
                  <template #button-content>
                      <div class="avatar-xs">
                          <span class="avatar-title rounded-circle">{{nama.charAt(0).toUpperCase()}}</span>
                      </div>
                  </template>
                  <b-dropdown-header id="dropdown-header-label">
                    <div class="d-flex">
                      <b-avatar variant="primary" :text="nama.charAt(0).toUpperCase()" class="mr-2 avatar-xs"></b-avatar>
                      <div class="d-flex justify-content-start align-items-center">
                        <span class="user-name default-text-style-profile"><span class="letter05">{{nama}}</span></span>
                        <!-- <span class="d-none d-xl-inline-block mx-2 default-text-style-profile font-size-12"> <i class="bx bxs-circle text-green"></i> <span class="text-muted letter05"> Active</span></span> -->
                      </div>
                    </div>
                  </b-dropdown-header>
                  <b-dropdown-item href="/settings">
                    <img src="@/assets/images/icon/setting.svg" alt height="100%" />
                    <span class="ml-3 default-text-style-profile">Settings</span>
                  </b-dropdown-item>
                  <b-dropdown-item href="/packages">
                    <img src="@/assets/images/icon/upgrade.svg" alt height="100%" />
                    <span class="ml-3 default-text-style-profile">Upgrade</span>
                  </b-dropdown-item>
                  <!-- <b-dropdown-item href="/payment/histories">
                    <img src="@/assets/images/icon/payment-card.png" alt height="100%" />
                    <span class="ml-3 default-text-style-profile">Payment History</span>
                  </b-dropdown-item> -->
                  <b-dropdown-divider></b-dropdown-divider>
                  <b-dropdown-item href="/logout">
                    <img src="@/assets/images/icon/logout.svg" alt height="100%" />
                    <span class="ml-3 default-text-style-profile">Sign Out</span>
                  </b-dropdown-item>
                </b-dropdown>
              </div>
              <Profile/>
              <Password/>
              <EditProfile/>
            </div>
        </div>
    </nav>
  </div>
</template>

<script>
import Profile from "../components/widgets/profile.vue";
import Password from "../components/widgets/editPassword.vue";
import EditProfile from "../components/widgets/editProfile.vue";

export default {
  name: 'NavPackage',
  components: {
    Profile,
    Password,
    EditProfile
  },
  computed: {
    nama() {
      return this.$store.getters['user/fullname']
    },
    isLogin() {
      return this.$store.getters['auth/user'] != null
    }
  }
}
</script>

<style scoped>
.logos{
    width: 126px;
    height: 30px;
}
.display-mobile{
  display: none;
}
@media (max-width: 806px) {
  .display-web{
    display: none;
  }
  .display-mobile{
    display: block;
  }
}
</style>
